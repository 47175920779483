.App {
  vertical-align: top;
  background-color: #bdcce9;
  /* min-height: 90vh;
  max-height: 780px; */
  height: 100vh;  
}

button {
  color: yellow;
  background-color: blue;
  padding: 5px;
  border: 1px solid #333;
  margin: 10px;
  font-size: 20px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px #333;	
}

button:hover {
  background-color: red;
  color: white;
  box-shadow: 3px 3px 3px #333;
}

.btn:hover {
  background-color: red;
  color: white;
  box-shadow: 3px 3px 3px #333;
}

button:active {
  background-color: red;
}

h1 {
  text-shadow: 3px 3px 3px #333;
  box-shadow: 3px 3px 3px #333;
  color: red;
  font-size: 32pt;
  background-color: DarkOliveGreen;
  font-family: "Times New Roman";
  padding: 4px;
  border: 2px solid #333;
  margin: 2px;
  display: table;
  margin-top: 20px;
  border-radius: 20px;
}

h2 {
  text-shadow: 3px 3px 3px #999;
  box-shadow: 3px 3px 3px #333;
  color: purple;
  font-size: 22pt;
  background-color: peachpuff;
  font-family: "Times New Roman";
  padding: 4px;
  border: 2px solid #333;
  margin: 2px;
  display: table;
  margin-top: 20px;
  border-radius: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 3s linear;
  }
} */

.App-header {
  background-color: #bdcce9;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: left;
  vertical-align: top;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: -10px;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.content {
  background-color: #bdcce9;
  margin: 10px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #333;
}

p {
  font-family: 'Tahoma';
  font-size: 10pt;
  padding: -5px;
}

.btn {
  font-size: 9pt;
  margin-left: 2px;
  box-shadow: 3px 3px 3px #333;
  color: white;
  display: inline-block;
  padding: 4px;
  background-color: rgb(73, 136, 73);
  text-decoration: underline;
  border: 1px solid black;
  border-radius: 10px;
  /* width: 230px; */
  max-width: 250px;
  margin-top: 2px;
  margin-bottom: 2px;
}

table {
  font-family: 'Courier New';
  font-size: 9pt;  
  margin-left: 2px;
}

@media screen and (max-width: 600px) {
  .btn {
    width: 200px;
    background-color: purple;
    font-size: 7pt;
  }
  .content {
    max-width: 400px;
  }
  p {
    font-family: tahoma;
    font-size: 9pt;
  }
  .span {
    font-family: tahoma;
    font-size: 9pt;
  }
}

@media screen and (max-width: 599px) {
  .btn {
    max-width: 80px;
    background-color: purple;
    font-size: 7pt;
  }
  .content {
    max-width: 400px;
  }
  p {
    font-size: 9pt;
  }
}

.subtitle {
  font-size: 9pt;
  font-family: tahoma;
  font-style: italic;
  color: blue;
}

.gfg:hover {
  z-index: -1;
  font-size: 20px;
  text-align: center;
  transform-style: all;
  transition-duration: 1s;
}